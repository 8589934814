import { animate, spring, inView } from "motion"

const inViewMarginPercent = 40
const inViewMarginPx = (inViewMarginPercent * window.innerHeight) / 100 / 2

window.onresize = (e) => {
  const inViewMarginPx = (inViewMarginPercent * window.innerHeight) / 100 / 2
}

const elements = document.querySelectorAll(".reader p, .annotation")

// Set the initial styles for the elements on page load
elements.forEach((element) => {
  element.style.transform = "scale(0.9) translateY(20px)"
  element.style.opacity = "0.5"
})

inView(
  ".reader p, .annotation",
  (info) => {
    const { target } = info

    const anim = animate(
      target,
      {
        y: [20, 0],
        scale: [0.9, 1],
        opacity: [0.5, 1],
      },
      { 
        easing: spring({ stiffness: 100 }),
        amount: "all"
      }
    )

    return (leaveInfo) => {
      // Reverse the animation when the elements leave the view
      // animate(
      //   target,
      //   {
      //     y: [20, 0],
      //     scale: [0.9, 1],
      //     opacity: [0.5, 1],
      //   },
      //   { easing: spring({ stiffness: 100 }), direction: "reverse" }
      // )
      animate(
        target,
        {
          y: [0, 20],
          scale: [1, 0.9],
          opacity: [1, 0.5],
        },
        { easing: spring({ stiffness: 100 })}
      )
    }
  },
  { margin: `-${inViewMarginPx}px 0px -${inViewMarginPx}px 0px` },
  { amount: "all" }
)
